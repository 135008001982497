/**@jsxImportSource theme-ui */
import React from "react";
import { DefaultLayout as Layout } from "../Layout/Default";
import { SEO } from "../components/SEO";

import { GenericPage } from "../data/ContentfulGenericPage";
import Hero from "../components/home/Hero";

const HomePage = () => {
  const homeData = GenericPage.find((page: any) => (page.url = "/"));
  return (
    <Layout
      showMasterDisclaimer={homeData?.showMasterDisclaimer}
      showCustomDisclaimers={homeData?.showCustomDisclaimers}
      disclaimers={homeData?.disclaimers}
    >
      <SEO
        title={homeData?.seo.title || undefined}
        description={homeData?.seo.description || undefined}
      />
      <Hero
        homeHero={homeData?.content?.find(
          (content: any) => content.internalName == "Home Hero"
        )}
      />
    </Layout>
  );
};

export default HomePage;
