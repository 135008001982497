/** @jsxImportSource theme-ui */
import { jsx } from "theme-ui";
import React from "react";

import { Link, Image } from "../utils";
import Background from "../../images/home-hero-background.svg";
import { MDXRenderer } from "gatsby-plugin-mdx";

const Hero = (props: any) => {
  return (
    <div
      sx={{
        backgroundImage: `url(${Background}), linear-gradient(180deg, #00A99D 0%, #1C174B 100%)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        pt: ["30px", null, null, 5],
        pb: 5,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: ["column-reverse", null, null, "row"],
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "1024px",
          px: "20px",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: ["center", null, null, "flex-start"],
            maxWidth: "380px",
            color: "brand.white",
            textAlign: ["center", null, null, "left"],
            pt: [3, null, null, 0],
          }}
        >
          <h1
            sx={{
              fontSize: "48px",
              lineHeight: "52px",
              fontWeight: "bold",
              mb: 3,
            }}
          >
            {props.homeHero.h1}
          </h1>
          {props.homeHero.copy && (
            <div
              sx={{
                mb: 4,
                "& p": { color: "white" },
              }}
            >
              <MDXRenderer>{props.homeHero.copy.childMdx.body}</MDXRenderer>
            </div>
          )}
          {props.homeHero.link && <Link link={props.homeHero.link} />}
        </div>
        <div>
          <Image
            image={props.homeHero.image}
            sx={{
              mr: [0, null, null, "15px"],
              ml: [0, null, null, 5],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
